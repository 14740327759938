<script setup lang="ts">
import { NuxtLink } from '#components'
const { to } = defineProps<{
  to?: string
  truncate?: boolean
}>()

defineEmits<{
  click: []
}>()

const componentBinding = to
  ? { is: NuxtLink, to }
  : { is: 'button', type: 'button' }
</script>

<template>
  <component
    :is="componentBinding.is"
    v-bind="componentBinding"
    class="focus:ring-secondary-200 hover:bg-neutral-light group relative flex min-h-9 w-full items-center rounded-md p-2 pr-4 text-neutral-900 outline-none ring-offset-2 focus:ring focus:ring-2"
    @click="$emit('click')"
  >
    <span
      class="body-2 w-full max-w-prose text-left text-neutral-900"
      :class="{
        'truncate whitespace-nowrap': truncate,
      }"
    >
      <slot />
    </span>
  </component>
</template>
