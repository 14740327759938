<script setup lang="ts">
const open = defineModel<boolean>('open')

const { enter, meta_k } = useMagicKeys()
const route = useRoute()
const router = useRouter()
const workspace = useWorkspace()

const commandInput = ref('')

const claimIdMatch = computed(() => {
  return commandInput.value.match(/\d+/) &&
    commandInput.value.trim().length === 15
    ? commandInput.value
    : ''
})

const isPolicyId = computed(() => {
  return commandInput.value.match(/^policy_\d+/)
})

const { data, status, refresh } = useApiFetch<{ id: string; name: string }[]>(
  () =>
    `/api/workspace/${workspace.workspaceId}/find-claim-connectors/${claimIdMatch.value}`,
  { immediate: false, watch: false },
)

const connectors = useSimpleSearch(
  computed(() => workspace.connectors),
  commandInput,
  'name',
)

const pages = computed(() => [
  {
    label: 'Dashboard',
    name: 'index',
    to: '/',
  },
  {
    label: 'Policies',
    name: 'policies',
    to: '/policies',
  },
  {
    label: 'Claims',
    name: 'claims',
    to: '/claims',
  },
  {
    label: 'Exclusions',
    name: 'exclusions',
    to: '/exclusions',
  },
  ...(workspace.canManageConnector
    ? [
        {
          label: 'Create Exclusion',
          name: 'exclusions-create',
          to: '/exclusions/create',
        },
      ]
    : []),
  {
    label: 'Changelog',
    name: 'changelog',
    to: '/changelog',
  },
])

const pageList = useSimpleSearch(pages, commandInput, 'label')

const navToClaim = async (connectorId: string) => {
  // this work around is needed because of the router.push('/claims') on the claim id page
  // https://gitlab.com/rialtic/workflows/console-ui/-/blob/235d8e0c9a31fc9fe883b0e4aceac181c1905db2/apps/console/pages/claims/%5Bid%5D.vue#L137
  if (route.name === 'claims-id') {
    await router.push('/claims')
  }
  if (workspace.connectorId !== connectorId) {
    workspace.setActiveConnector(connectorId)
  }
  router.push({
    name: 'claims-id',
    params: {
      id: claimIdMatch.value,
    },
  })
}

const navToPage = async (page: { to: string }) => {
  await router.push(page.to)
  open.value = false
}

const switchConnector = async (connectorId: string) => {
  if (workspace.connectorId !== connectorId) {
    workspace.setActiveConnector(connectorId)
  }
  open.value = false
}

watch(
  claimIdMatch,
  () => {
    if (claimIdMatch.value) refresh()
  },
  { immediate: true },
)

whenever(enter, () => {
  if (claimIdMatch.value && data.value?.length) {
    return navToClaim(data.value[0].id)
  }

  if (isPolicyId.value) {
    return navToPage({
      to: `/policies/${commandInput.value}`,
    })
  }

  if (connectors.value.length) {
    return switchConnector(connectors.value[0].id)
  }

  if (pageList.value.length) {
    return navToPage(pageList.value[0])
  }
})

whenever(meta_k, () => {
  open.value = !open.value
})

whenever(
  () => !open.value,
  () => {
    commandInput.value = ''
  },
)
</script>

<template>
  <UiDialog v-model="open" max-width="520px">
    <div class="card">
      <header class="flex items-center justify-between border-b px-2 py-1">
        <i
          class="i-ph-magnifying-glass pointer-events-none absolute inline-block h-5 w-5 text-neutral-700"
        />
        <input
          v-model="commandInput"
          class="w-full flex-1 px-7 py-2 text-sm text-xs leading-4 text-neutral-900 outline-none"
          autofocus
          placeholder="Search..."
        />
        <UiBtnClose @click="open = false" />
      </header>

      <ul v-if="claimIdMatch" class="p-1">
        <li
          v-if="status === 'pending'"
          class="flex items-center justify-center py-1 text-sm text-neutral-800"
        >
          <UiLoading class="text-secondary mr-2 h-5 w-5" />
          Searching for claim across all lines of business
        </li>

        <li v-for="(connector, index) in data" :key="connector.id">
          <UiListItemAction @click="navToClaim(connector.id)">
            <span class="flex items-center justify-between">
              {{ connector.name }}

              <i
                v-if="index === 0"
                class="i-ph-key-return-bold inline-block h-4 w-4 text-neutral-800"
              />
            </span>
          </UiListItemAction>
        </li>
      </ul>
      <template v-if="commandInput.length">
        <ul v-if="connectors.length" class="p-1">
          <li class="subtitle-1 px-2 text-neutral-800">Connectors</li>

          <li v-for="(connector, index) in connectors" :key="connector.id">
            <UiListItemTick
              :checked="connector.id === workspace.connectorId"
              @click="switchConnector(connector.id)"
            >
              <span class="flex items-center justify-between">
                {{ connector.name }}

                <i
                  v-if="index === 0 && !(claimIdMatch && data?.length)"
                  class="i-ph-key-return-bold inline-block h-4 w-4 text-neutral-800"
                />
              </span>
            </UiListItemTick>
          </li>
        </ul>

        <ul v-if="pageList.length" class="p-1">
          <li class="subtitle-1 px-2 text-neutral-800">Quick links</li>

          <li v-for="(page, index) in pageList" :key="page.to">
            <UiListItemTick
              :checked="page.name === route.name"
              @click="navToPage(page)"
            >
              <span class="flex items-center justify-between">
                {{ page.label }}

                <i
                  v-if="
                    index === 0 &&
                    !((claimIdMatch && data?.length) || connectors.length)
                  "
                  class="i-ph-key-return-bold inline-block h-4 w-4 text-neutral-800"
                />
              </span>
            </UiListItemTick>
          </li>
        </ul>

        <ul v-if="isPolicyId" class="p-1">
          <li class="subtitle-1 px-2 text-neutral-800">Go to policy</li>

          <li>
            <UiListItemAction
              @click="navToPage({ to: `/policies/${commandInput}` })"
            >
              <span class="flex items-center justify-between">
                {{ commandInput }}

                <i
                  class="i-ph-key-return-bold inline-block h-4 w-4 text-neutral-800"
                />
              </span>
            </UiListItemAction>
          </li>
        </ul>
      </template>
      <footer class="p-2">
        <p class="text-xs text-neutral-700">
          Search for claims, pages, lines of business, and more
        </p>
      </footer>
    </div>
  </UiDialog>
</template>
