<script setup lang="ts">
import { formatDistanceToNow, isToday, isTomorrow, isYesterday } from 'date-fns'
import { useAppConfiguration } from '~/stores/appConfiguration'
import { useConnectorAnalytics } from '~/stores/connectorAnalytics'
import { useWorkspace } from '~/stores/workspace'

const { $auth, $datadog, $auth0, $pwa } = useNuxtApp()

const { isAuthenticated, user, logout } = $auth0()
const config = useAppConfig()
const appConfig = useAppConfiguration()
const connectorAnalytics = useConnectorAnalytics()
const { activePreset } = useDatePresets()
const isMounted = useMounted()
const router = useRouter()
const workspace = useWorkspace()
const { timezone } = useWorkspaceTimezone()

const commandPaletteOpen = ref(false)
const navOpen = ref(false)

const onSignOut = async () => {
  try {
    if (isAuthenticated.value)
      await logout({
        logoutParams: {
          returnTo: `${location.origin}/auth/login`,
        },
      })
    await $auth.tokenInvalidate()
    await connectorAnalytics.clearCacheDb()
  } catch (error) {
    $datadog.addError(error)
  }

  workspace.$reset()
  $datadog.clearUser()
  router.push('/signin')
}

const showWorkspaceSwitcher = ref(false)
const authInit = ref(!!$auth.profile.sub || !!user.value?.id)

// TODO: improve this by canceling the watcher when the profile is set
watch(
  [() => $auth.profile, user],
  ([profile, auth0User]) => {
    if (profile.sub || auth0User) {
      authInit.value = true
    }
  },
  {
    immediate: true,
  },
)

const version = computed(() =>
  workspace.isRialticUser && !config.tenant.hideVersion
    ? `v${config.version}`
    : '',
)

const mobileConnectorMenu = ref(false)

const setActiveConnector = (id: string) => {
  workspace.setActiveConnector(id)
  mobileConnectorMenu.value = false
}

// 15 minutes in milliseconds
const timeTillIdle = 900000

const clock = useClock()
const { idle } = useIdle(timeTillIdle)

whenever(
  () =>
    !workspace.isClaimEval &&
    clock.timestamp.value > connectorAnalytics.syncConnectorAfter,
  () => {
    connectorAnalytics.syncConnectorAfter = Number.POSITIVE_INFINITY
    workspace.syncPaApi({ flush: true })
  },
  {
    immediate: true,
  },
)

whenever(
  () =>
    !idle.value &&
    !workspace.isClaimEval &&
    clock.timestamp.value > connectorAnalytics.retryAfterTimestamp,
  () => {
    if (
      !appConfig.feature_periodic_refresh ||
      workspace.activeConnector?.system_document?.prevent_realtime_sync
    ) {
      return
    }
    connectorAnalytics.refreshConnectorAnalytics(
      workspace.workspaceId,
      workspace.connectorId,
    )
  },
  {
    immediate: true,
  },
)

const formatDay = (date: Date) =>
  isYesterday(date)
    ? 'yesterday'
    : isToday(date)
      ? 'today'
      : isTomorrow(date)
        ? 'tomorrow'
        : formatDistanceToNow(date, { addSuffix: true })

const displayTimes = computed(() => {
  if (workspace.isClaimEval) {
    return {
      lastUpdated: '',
      nextRefresh: 'Refreshes are trigger manually after batch runs',
    }
  }
  if (!connectorAnalytics.lastRefreshedAt) {
    return {
      lastUpdated: '',
      nextRefresh: 'Refreshes in about 1 hour',
    }
  }
  const lastUpdatedDate = new Date(connectorAnalytics.lastRefreshedAt)
  const nextRefreshDate = new Date(connectorAnalytics.nextRefreshEstimate)
  const formatter = timezoneFormatter(
    timezone.value,
    intlDateFormats.timeWithTimeZoneName,
  )

  return {
    lastUpdated: `Data last updated ${formatDay(
      lastUpdatedDate,
    )} at ${formatter.format(lastUpdatedDate)}`,
    nextRefresh: `Refreshes ${formatDay(nextRefreshDate)} at ${formatter.format(
      nextRefreshDate,
    )}`,
  }
})
</script>

<template>
  <div>
    <data class="hidden" :value="config.version" label="version" />
    <ClientOnly>
      <UiTransitionFade
        enter-active-class="transition-duration-600 transition-opacity"
        leave-active-class="transition-duration-600 transition-opacity"
      >
        <div v-if="authInit">
          <AppNavDrawer
            v-if="isMounted"
            v-model="navOpen"
            :has-update="$pwa?.needRefresh"
            :version="version"
            @update="$pwa?.updateServiceWorker()"
          >
            <template #nav="{ isMini, toggleMini }">
              <NavMenu
                :mini="isMini"
                :nav-open="navOpen"
                @nav-open="toggleMini(false)"
              />
            </template>
            <template #toolbar>
              <AppToolbar class="sticky top-0">
                <template #default>
                  <div
                    v-if="appConfig.maintenance"
                    class="bg-warning border-warning-dark absolute bottom-0 left-0 h-1 w-full"
                  />
                  <div class="flex h-full items-center">
                    <button
                      class="mr-2 h-8 w-8 rounded-full lg:hidden"
                      @click="navOpen = !navOpen"
                    >
                      <div class="i-ph-list h-6 w-6" />
                    </button>

                    <div
                      class="lg:max-w-auto flex h-full max-w-[62vw] items-center space-x-3 md:hidden"
                    >
                      <button
                        class="bg-surface-bg outline-secondary-100 flex items-center overflow-hidden rounded-md border hover:outline"
                        @click="mobileConnectorMenu = true"
                      >
                        <div
                          class="subtitle-2 bg-surface h-full max-w-[68vw] truncate border-r p-2 text-neutral-800"
                        >
                          {{
                            workspace.activeConnector?.name ||
                            'Select a connector'
                          }}
                        </div>
                        <div
                          class="flex h-full items-center space-x-1 px-2 py-1 text-neutral-900"
                        >
                          <div class="i-ph-calendar h-5 w-5" />

                          <div
                            class="body-2 h-full max-w-[68vw] truncate text-ellipsis text-xs"
                          >
                            {{ activePreset.label }}
                          </div>
                        </div>
                      </button>

                      <Teleport to="body">
                        <div>
                          <UiTransitionFade>
                            <div
                              v-if="mobileConnectorMenu"
                              class="backdrop-blur-px fixed inset-0 z-50 h-screen backdrop-filter"
                              @click="mobileConnectorMenu = false"
                            />
                          </UiTransitionFade>
                          <Transition
                            enter-active-class="transition-transform duration-150"
                            enter-from-class="-translate-y-full"
                            enter-to-class="translate-y-0"
                            leave-active-class="transition-transform duration-100"
                            leave-from-class="translate-y-0"
                            leave-to-class="-translate-y-full"
                          >
                            <div
                              v-if="mobileConnectorMenu"
                              class="bg-surface fixed left-0 right-0 top-0 z-50 h-auto pb-2 shadow-md"
                            >
                              <header
                                class="flex items-center justify-between px-4 py-1"
                              >
                                <p class="h3 text-neutral-700">
                                  Lines of business
                                </p>

                                <button
                                  class="btn-icon"
                                  type="button"
                                  @click="mobileConnectorMenu = false"
                                >
                                  <div class="i-ph-x-bold h-5 w-5" />
                                </button>
                              </header>

                              <div class="h-full flex-col space-y-4 p-2">
                                <ul class="flex-col space-y-1">
                                  <li v-for="connector in workspace.connectors">
                                    <UiListItemTick
                                      :checked="
                                        connector.id === workspace.connectorId
                                      "
                                      @click="setActiveConnector(connector.id)"
                                    >
                                      {{ connector.name }}
                                    </UiListItemTick>
                                  </li>
                                </ul>

                                <SelectBatchRun
                                  v-if="workspace.isClaimEval"
                                  data-testid="selectBatchRun"
                                />
                                <SelectTimeFrame
                                  v-else-if="workspace.connectorId"
                                  class="w-full"
                                  placement="bottom-start"
                                  data-testid="selectTimeFrame"
                                />
                              </div>
                            </div>
                          </Transition>
                        </div>
                      </Teleport>
                    </div>

                    <div
                      class="lg:max-w-auto hidden h-full max-w-[62vw] items-center space-x-2 md:flex"
                    >
                      <div class="subtitle-2 text-neutral-800">
                        {{
                          workspace.activeConnector?.name || $route.meta.title
                        }}
                      </div>
                      <SelectBatchRun
                        v-if="workspace.isClaimEval"
                        data-testid="selectBatchRun"
                      />
                      <SelectTimeFrame
                        v-else-if="workspace.connectorId"
                        placement="bottom-start"
                        data-testid="selectTimeFrame"
                      />
                    </div>
                    
                  </div>
                </template>
                <template #user-menu>
                  <div
                    v-if="appConfig.maintenance || workspace.underMaintenance"
                    class="bg-warning-200 border-warning-dark text-warning-800 rounded border-2 border-dashed px-2 py-1 text-xs"
                  >
                    Under Maintenance
                  </div>
                  <Clock v-if="appConfig.feature_policy_metadata" />
                  <UiTooltip
                    v-if="appConfig['feature_periodic_refresh']"
                    :offset="8"
                    tabindex="-1"
                  >
                    <template #activator="{ isOpen }">
                      <button
                        class="btn btn-icon border-transparent text-neutral-400 outline-none focus:text-neutral-700"
                        :class="{
                          'text-neutral-700': isOpen,
                        }"
                        aria-label="Refresh icon"
                        tabindex="-1"
                      >
                        <div
                          class="stack h-7 w-7 transform-gpu items-center justify-center"
                        >
                          <div
                            v-if="appConfig['feature_periodic_refresh']"
                            class="i-ph-clock-countdown scale-80 block h-7 w-7"
                          />
                          <div
                            class="origin-center transform-gpu opacity-0 transition-all"
                            :class="{
                              'scale-110 opacity-80':
                                connectorAnalytics.loading,
                              'scale-80': !connectorAnalytics.loading,
                            }"
                          >
                            <div
                              class="i-fluent-spinner-ios-20-filled text-secondary h-7 w-7"
                              :class="{
                                'animate-spin': connectorAnalytics.loading,
                              }"
                            />
                          </div>
                        </div>
                      </button>
                    </template>

                    <ul class="body-2 p-2">
                      <li class="">{{ displayTimes.lastUpdated }}</li>
                      <li class="">{{ displayTimes.nextRefresh }}</li>
                    </ul>
                  </UiTooltip>
                  <button
                      class="btn-icon"
                      type="button"
                      @click="commandPaletteOpen = !commandPaletteOpen"
                    >
                      <div class="i-ph-magnifying-glass h-5 w-5 text-neutral-700" />
                    </button>
                    <CommandPalette v-model:open="commandPaletteOpen" />
                  <AppUserMenu
                    :logo-url="workspace.activeWorkspace?.logo ?? ''"
                    :profile-img-url="user?.picture ?? ''"
                  >
                    <ul class="flex flex-col p-2">
                      <li
                        v-if="
                          workspace.hasMany &&
                          !config.tenant.singleTenantWorkspaceId
                        "
                      >
                        <button
                          class="user-menu-link"
                          @click="showWorkspaceSwitcher = true"
                        >
                          Switch Workspace
                        </button>
                      </li>
                      <li>
                        <button class="user-menu-link" @click="onSignOut()">
                          Log out
                        </button>
                      </li>
                    </ul>
                  </AppUserMenu>
                </template>
              </AppToolbar>
            </template>

            <div class="flex flex-1 flex-col">
              <slot />
            </div>
          </AppNavDrawer>
          <UiDialog
            v-if="!config.tenant.singleTenantWorkspaceId"
            v-model="showWorkspaceSwitcher"
            max-width="600px"
          >
            <header class="flex items-center justify-between p-4">
              <h1 class="h1">Workspaces</h1>

              <button
                class="btn btn-icon border-transparent"
                @click="showWorkspaceSwitcher = false"
              >
                <div class="i-ph-x-bold" />
              </button>
            </header>
            <ul class="grid grid-cols-2 gap-4 px-4 pb-5">
              <li
                v-for="(workspaceData, workspaceId) in workspace.userWorkspaces"
              >
                <LazyWorkspaceCard
                  v-if="workspaceData"
                  :active="workspace.workspaceId === workspaceId"
                  :workspace="workspaceData"
                  @done="showWorkspaceSwitcher = false"
                />
              </li>
            </ul>
          </UiDialog>
        </div>
        <AppSplashScreen v-else />
      </UiTransitionFade>
    </ClientOnly>
  </div>
</template>

<style scoped>
.user-menu-link {
  @apply body-2 hover:bg-surface-bg inline-flex w-full rounded-lg px-4 py-2 text-left;
}

.stack {
  display: grid;
  place-content: center;

  > * {
    grid-area: 1 / 1;
  }
}
</style>
